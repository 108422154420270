<template>
  <div v-if="showPagination" class="pagination q-lg flex flex-center">
    <mi-pagination
      :model-value="currentPage"
      :max="maxPage"
      :max-pages="5"
      size="14px"
      boundary-links
      direction-links
      boundary-numbers
      ellipses
      @update:model-value="handleChange"
    ></mi-pagination>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { SET_TEST_CASE_PAGE, ARE_TEST_CASES_LOADING } from '@/store/modules/validations/mutationTypes'

  const { mapState, mapMutations, mapActions } = createNamespacedHelpers('validations')

  export default {
    name: 'TestCasesPagination',
    computed: {
      ...mapState(['testCaseFilters', 'testCasePageable']),
      showPagination() {
        return this.testCasePageable.totalPages > 0
      },
      currentPage() {
        return this.testCasePageable.page + 1
      },
      maxPage() {
        return this.testCasePageable.totalPages
      }
    },
    methods: {
      ...mapActions(['getTestCasesList']),
      ...mapMutations({ SET_TEST_CASE_PAGE, ARE_TEST_CASES_LOADING }),
      async handleChange(value) {
        this.SET_TEST_CASE_PAGE({ page: value - 1 })
        await this.fetchTestCases()
      },
      async fetchTestCases() {
        const { page, pageSize } = this.testCasePageable
        const params = { ...this.testCaseFilters, page, pageSize }

        this.ARE_TEST_CASES_LOADING(true)

        try {
          await this.getTestCasesList({ ...params })
        }
        finally {
          this.ARE_TEST_CASES_LOADING(false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pagination {
    padding: 8px 0 16px;
  }
</style>
