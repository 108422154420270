<template>
  <mi-dialog
    :model-value="modelValue"
    class="upload-excel-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-sm"> Upload file </h5>

    <!-- Subtitle -->
    <p class="q-mb-lg"> Upload your Excel file to be validated. </p>

    <mi-form @submit="handleUploadFormSubmit">
      <!-- File upload -->
      <mi-file
        v-model="file"
        :rules="fileValidationRules"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        class="q-mb-sm"
        label="File"
        lazy-rules="ondemand"
        clearable
        outlined
      >
        <template #prepend>
          <mi-icon name="file-upload"></mi-icon>
        </template>
      </mi-file>

      <!-- File name -->
      <mi-text-field
        v-model="filename"
        :rules="filenameValidationRules"
        class="q-mb-md"
        label="File name"
        placeholder="e.g. Test Case"
        clearable
        outlined
      ></mi-text-field>

      <!-- Submit btn -->
      <div class="row justify-end">
        <mi-btn v-close-popup flat> Cancel </mi-btn>
        <mi-btn :loading="isFileUploading" class="q-ml-sm" type="submit"> Upload </mi-btn>
      </div>
    </mi-form>
  </mi-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import { uploadTestCase } from '@/api'
  import { maxLength, required } from '@/utils/validators'
  import notify from '@/utils/notify'

  const { mapActions } = createNamespacedHelpers('validations')

  export default {
    name: 'TestCaseDialogUpload',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      isFileUploading: false,
      file: null,
      filename: '',
      fileValidationRules: [required],
      filenameValidationRules: [
        required,
        maxLength(100)
      ]
    }),
    watch: {
      file(current) {
        if (current && !this.filename.trim().length) {
          this.filename = current?.name?.split('.').slice(0, -1).join('.') || ''
        }
      },
      modelValue(current) {
        if (!current) {
          this.file = null
          this.filename = ''
        }
      }
    },
    methods: {
      ...mapActions(['getTestCasesList']),

      async handleUploadFormSubmit() {
        const { file, filename } = this

        this.isFileUploading = true

        try {
          await uploadTestCase({ file, filename })

          notify({
            title: 'Uploaded',
            content: `Test case file "${ filename }" was successfully uploaded`,
            type: 'positive'
          })

          this.emitUpdateModelValue()
          this.$nextTick(this.getTestCasesList)
        }
        finally {
          this.isFileUploading = false
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .upload-excel-dialog .q-dialog__inner > .mi-card {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
  }
</style>
