export const BROWSE_PORTFOLIO_WORLDS = Object.freeze({
  YELLOW: { label: 'Yellow World', key: 'YELLOW' },
  GREEN: { label: 'Green World', key: 'GREEN' }
})

export const BROWSE_PORTFOLIO_WORLDS_LISTS_YELLOW = Object.freeze({
  CATEGORIES: {
    label: 'Categories',
    key: 'CATEGORIES',
    state: 'categoriesItems'
  },
  OPTIONS: {
    label: 'Option(s)',
    key: 'OPTIONS',
    text: 'Select a category to see all available options',
    state: 'categoriesItems'
  },
  CHOICES: {
    label: 'Choice(s)',
    key: 'CHOICES',
    text: 'Select options to see all available choices',
    state: 'categoriesItems'
  }
})

export const BROWSE_PORTFOLIO_WORLDS_LISTS_GREEN = Object.freeze({
  HIGH_GROUPS: {
    label: 'High Groups',
    key: 'HIGH_GROUPS',
    state: 'highGroups'
  },
  COMPONENTS: {
    label: 'Components',
    key: 'COMPONENTS',
    text: 'Select category to see all available components',
    state: 'components'
  },
  COMPONENT_VARIANTS: {
    label: 'Component Variants',
    key: 'COMPONENT_VARIANTS',
    text: 'Select component to see all component variants',
    state: 'componentVariants'
  }
})
