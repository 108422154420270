<template>
  <mi-td class="pin-content">
    <div class="flex justify-between">
      <div>
        <mi-tr-action-btn
          v-if="!row.isPinned"
          icon="pin-outline"
          @click="togglePin"
        >
          <mi-tooltip> Pin case for everyone </mi-tooltip>
        </mi-tr-action-btn>
        <mi-tr-action-btn
          v-if="row.isPinned"
          :persistent="true"
          icon="pin"
          @click="showConfirmationDialog"
        >
          <mi-tooltip> Unpin case for everyone </mi-tooltip>
        </mi-tr-action-btn>
        <span class="expire-date">
          {{ setExpireDate() }}
        </span>
      </div>
      <div @click.stop>
        <mi-btn color="transparent">
          <q-icon class="text-dark" size="small">
            <img
              src="@/assets/images/dots_vertical.svg"
              alt="Pin/Unpin test cases"
            />
          </q-icon>
          <mi-menu>
            <q-list>
              <q-item
                v-if="!row.isPinned"
                v-close-popup
                clickable
                @click="togglePin"
              >
                <q-item-section>
                  <q-item-label>
                    Pin test case
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-else
                v-close-popup
                clickable
                @click="showConfirmationDialog"
              >
                <q-item-section>
                  <q-item-label>
                    Unpin test case
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-close-popup
                clickable
                :class="{ 'disabled': row.isPinned }"
                @click="deleteConfirmationDialog"
              >
                <q-item-section>
                  <q-item-label>
                    Delete
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </mi-menu>
        </mi-btn>
      </div>
    </div>
  </mi-td>

  <!-- Unpin for everyone Confirmation Dialog -->
  <mi-dialog
    v-model="isUnpinConfirmationDialogShown"
    class="unpin-confirmation-dialog"
    persistent
    @update:model-value="emitUpdateModelValue"
  >
    <h3
      class="q-mt-none q-mb-sm unpin-confirmation-header"
    >
      This will unpin for everyone
    </h3>

    <p
      class="unpin-confirmation-text"
    >
      By unpinning this Test case a new countdown of 30 days will begin
      and after that it will no longer be available for everybody.
      Are you sure you want to unpin?
    </p>

    <template #actions>
      <mi-btn v-close-popup flat> Cancel  </mi-btn>
      <mi-btn type="submit" @click="togglePin"> Unpin for everyone </mi-btn>
    </template>
  </mi-dialog>

  <!-- Delete Test case Confirmation Dialog -->
  <mi-dialog
    v-model="showDeleteConfirmationDialog"
    class="show-delete-confirmation-dialog"
    persistent
  >
    <h3
      class="q-mt-none q-mb-sm modal-confirmation-delete-header"
    >
      Delete Test case?
    </h3>

    <p
      class="modal-confirmation-delete"
    >
      Are you sure you want to delete test case
      <span class="text-weight-bold"> {{ row.name }} </span>?
    </p>

    <template #actions>
      <mi-btn v-close-popup class="mi-btn-cancel" flat> Cancel  </mi-btn>
      <mi-btn type="submit" class="mi-btn-delete" @click="deleteTestCase"> Delete </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>

  import { togglePinTestCase, deleteDailyTestCase } from '@/api'
  import MiTrActionBtn from '@/packages/@mi-library/MiTable/MiTrActionBtn.vue'
  import MiTd from '@/packages/@mi-library/MiTable/MiTd.vue'
  import MiTooltip from '@/packages/@mi-library/MiTooltip/MiTooltip.vue'
  import { getRemainingDays } from '@/utils/formatDate'
  import MiMenu from '@/packages/@mi-library/MiMenu/MiMenu.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { ref } from 'vue'
  import MiDialog from '@/packages/@mi-library/MiDialog/MiDialog.vue'
  import notify from '@/utils/notify'
  import { BASIC_INFO } from '@/plugins/quasar/notifications/notificationTypes'

  export default {
    name: 'TestCasesPin',
    components: { MiDialog, MiBtn, MiMenu, MiTooltip, MiTd, MiTrActionBtn },
    props: {
      row: {
        type: Object,
        required: true
      },
      fetchTestCases: {
        type: Function,
        required: true
      },
      params: {
        type: Object,
        required: true
      }
    },
    emits: ['update:model-value'],
    setup(props, { emit }) {
      const isUnpinConfirmationDialogShown = ref(false)
      const showDeleteConfirmationDialog = ref(false)
      const refreshTestCases = async () => {
        const { page, pageSize } = props.params.testCasePageable
        await props.fetchTestCases({ ...props.params.testCaseFilters, page, pageSize })
      }

      const togglePin = async event => {
        event.stopPropagation()
        isUnpinConfirmationDialogShown.value = false
        await togglePinTestCase(props.row.id)
        await refreshTestCases()
      }

      const setExpireDate = () => {
        if (props.row.expireDate) {
          return `expires in ${ getRemainingDays(props.row.expireDate) } days`
        }
        return props.row.isPinned ? 'pinned' : ''
      }

      const showConfirmationDialog = async event => {
        event.stopPropagation()
        isUnpinConfirmationDialogShown.value = true
      }

      const emitUpdateModelValue = (value = false) => {
        emit('update:model-value', value)
      }

      const deleteConfirmationDialog = () => {
        showDeleteConfirmationDialog.value = true
      }

      const deleteTestCase = async () => {
        await deleteDailyTestCase(props.row.id)
        await refreshTestCases()
        notify({
          content: 'Test case deleted.',
          type: BASIC_INFO,
          position: 'bottom'
        })
      }

      return {
        togglePin,
        setExpireDate,
        showConfirmationDialog,
        isUnpinConfirmationDialogShown,
        emitUpdateModelValue,
        showDeleteConfirmationDialog,
        deleteConfirmationDialog,
        deleteTestCase
      }
    }
  }
</script>

<style lang="scss" scoped>
  // stylelint-disable declaration-no-important
  .mi-tr-action-btn.mi-btn.q-btn--dense.q-btn--fab {
    background: none !important;
    border-radius: 20px;
    width: 56px;
    height: 40px;

    ::v-deep(.mi-icon) {
      font-size: 18px !important;
    }

    ::v-deep(.mi-icon-pin-outline) {
      color: $mi-anthracite-300;
    }

    ::v-deep(.mi-icon-pin) {
      color: #5b6f85;
    }

    &:hover {
      background: $mi-anthracite-100 !important;
    }
  }

  .expire-date {
    color: #5b6f85 !important;
    padding: 8px 16px;
  }

  .pin-content {
    width: 285px;
  }
</style>

<style lang="scss">
  .unpin-confirmation-dialog {
    .q-dialog__inner > .mi-card {
      width: 656px;
      max-width: 656px;
    }

    .unpin-confirmation-header {
      line-height: 42px;
    }

    .unpin-confirmation-text {
      margin-bottom: 65px;
      color: $mi-anthracite-800;
      line-height: 24px;
    }

    .mi-btn {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .show-delete-confirmation-dialog {
    .q-dialog__inner > .mi-card {
      width: 644px;
      max-width: 656px;
    }

    .modal-confirmation-delete-header {
      line-height: 42px;
    }

    .modal-confirmation-delete {
      margin-bottom: 65px;
      color: $mi-anthracite-800;
      line-height: 24px;
    }

    .mi-btn {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: $mi-anthracite-300;
  }
</style>
