<template>
  <mi-dialog
    v-model="localModalValue"
    class="compare-date-modal"
    no-backdrop-dismiss
    persistent
    custom-design-dialog
  >
    <mi-form>
      <mi-card class="compare-date-modal__wrapper">
        <mi-card-section class="compare-date-modal__content">
          <div>
            <div class="compare-date-modal__header-wrapper">
              <h2
                class="compare-date-modal__header"
              >
                Select results to compare
              </h2>
              <mi-icon
                name="close"
                size="18px"
                class="compare-date-modal__close-icon"
                @click="closeModal"
              >
              </mi-icon>
            </div>
            <div class="compare-date-modal__date-picker">
              <mi-select
                v-model="resultDate"
                :display-value="resultDate || 'Select a date'"
                :options="dates"
                outlined
                required
                :error="errorFeedback?.stateResultDate || false"
                :error-message="errorFeedback.errorMessageResultDate|| ''"
                label="Result date"
                class="compare-date-modal__selected"
              >
              </mi-select>
              <mi-select
                v-model="compareDateWith"
                :display-value="compareDateWith || (isSpinning ? '' : 'Select a prior date')"
                :options="filteredDates"
                :class="{ 'disabled': !resultDate }"
                outlined
                :error="errorFeedback?.stateCompareDateWith || false"
                :error-message="errorFeedback?.errorMessageCompareDateWith || ''"
                label="Compare with"
                class="compare-date-modal__selected"
              >
                <template #prepend>
                  <mi-spinner v-if="isSpinning" size="20px"></mi-spinner>
                </template>
              </mi-select>
            </div>
          </div>
          <div class="compare-date-modal__btn-wrapper">
            <mi-btn
              color="primary"
              flat
              @click="closeModal"
            >
              Cancel
            </mi-btn>
            <mi-btn
              color="primary"
              class="submit-btn"
              @click="submitForm"
            >
              Generate Insights
            </mi-btn>
          </div>
        </mi-card-section>
      </mi-card>
    </mi-form>
  </mi-dialog>
</template>

<script>
  import { ref, computed, watch } from 'vue'
  import MiDialog from '@/packages/@mi-library/MiDialog/MiDialog.vue'
  import MiForm from '@/packages/@mi-library/MiForm/MiForm.vue'
  import { required } from '@/utils/validators'
  import MiSpinner from '@/packages/@mi-library/MiSpinner/MiSpinner.vue'
  import { useRouter } from 'vue-router'
  import { NOT_USUABLE_CHOICES_COMPARISON_ROUTE } from '@/router/routeNames'

  export default {
    name: 'CompareDateModal',
    components: {
      MiSpinner,
      MiDialog,
      MiForm
    },
    props: {
      modalValue: {
        type: Boolean,
        required: false,
        default: false
      },
      closeModal: {
        type: Function,
        required: false,
        default: () => {}
      },
      dates: {
        type: Array,
        required: true
      }
    },
    setup(props) {
      const localModalValue = ref(false)
      const resultDate = ref('')
      const compareDateWith = ref('')
      const requiredRule = required()
      const isSpinning = ref(false)
      const errorFeedback = ref({
        stateResultDate: false,
        errorMessageResultDate: '',
        stateCompareDateWith: false,
        errorMessageCompareDateWith: ''
      })
      const router = useRouter()

      const filteredDates = computed(() => props.dates.filter(date => {
        const [day, month, year] = date.split('/')
        const selectedDate = new Date(resultDate.value.split('/').reverse().join('-'))
        const currentDate = new Date(`${ year }-${ month }-${ day }`)
        return currentDate < selectedDate
      }))

      watch(() => props.modalValue, newVal => {
        localModalValue.value = newVal
        if (!newVal) {
          resultDate.value = ''
          compareDateWith.value = ''
        }
      })

      watch(resultDate, () => {
        if (compareDateWith.value) {
          compareDateWith.value = ''
          isSpinning.value = true
          setTimeout(() => {
            isSpinning.value = false
          }, 1000)
        }
      })

      const formatDate = dateString => {
        if (!dateString) return ''
        const [day, month, year] = dateString.split('/')

        return `${ year }-${ month }-${ day }`
      }

      const isFormValid = computed(() => !!(resultDate.value && compareDateWith.value))

      const errorMessage = error => {
        if (error) {
          errorFeedback.value = {
            stateResultDate: !resultDate.value,
            errorMessageResultDate: resultDate.value ? '' : 'Mandatory Field',
            stateCompareDateWith: !compareDateWith.value,
            errorMessageCompareDateWith: compareDateWith.value ? '' : 'Mandatory Field'
          }
        }
        else {
          errorFeedback.value = {
            stateResultDate: false,
            errorMessageResultDate: '',
            stateCompareDateWith: false,
            errorMessageCompareDateWith: ''
          }
        }
      }

      const submitForm = () => {
        if (isFormValid.value) {
          try {
            router.push({
              path: NOT_USUABLE_CHOICES_COMPARISON_ROUTE.path,
              query: {
                resultDate: formatDate(resultDate.value),
                compareDateWith: formatDate(compareDateWith.value)
              }
            })
          }
          catch (error) {
            errorMessage(error)
          }
        }
        else {
          errorMessage({ error: true })
        }
      }

      return {
        localModalValue,
        resultDate,
        compareDateWith,
        filteredDates,
        submitForm,
        requiredRule,
        isSpinning,
        isFormValid,
        errorFeedback
      }
    }
  }
</script>

<style lang="scss" scoped>
  .compare-date-modal {
    &__header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__wrapper {
      padding: 0;
      width: 475px;
      height: 250px;
    }

    &__content {
      padding: 24px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__header {
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      line-height: 24px;
    }

    &__date-picker {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
      padding: 30px 0 0;
    }

    &__selected {
      width: 50%;
    }

    &__btn-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }

    .disabled {
      pointer-events: none;
    }

    .compare-date-modal__close-icon {
      cursor: pointer;
    }
  }
</style>
