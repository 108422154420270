<template>
  <mi-btn
    class="not-usable-choices__btn"
    color="accent"
    @click="handleNotUsableChoicesModal"
  >
    <div class="not-usable-choices__btn-content">
      <mi-icon
        class="not-usable-choices__btn-icon"
        name="scan"
        size="14px"
      >
      </mi-icon>
      <span
        class="not-usable-choices__btn-text"
      >
        Data insights
      </span>
    </div>
  </mi-btn>
  <compare-date-modal
    :modal-value="modalOpen"
    :close-modal="handleNotUsableChoicesCloseModal"
    :dates="dates"
  >
  </compare-date-modal>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import MiIcon from '@/packages/@mi-library/MiIcon/MiIcon.vue'
  import CompareDateModal
    from '@/components/validations/test-cases/unusable-choices/not-usable-choices-modal/CompareDateModal.vue'
  import { getCompareDates } from '@/api'

  export default {
    name: 'NotUsableChoicesModal',
    components: {
      MiIcon,
      MiBtn,
      CompareDateModal
    },
    setup() {
      const modalOpen = ref(false)
      const dates = ref([])

      const handleNotUsableChoicesModal = () => {
        modalOpen.value = true
      }

      const handleNotUsableChoicesCloseModal = () => {
        modalOpen.value = false
      }

      const formatDate = dateString => {
        const [year, month, day] = dateString.split('-')
        return `${ day }/${ month }/${ year }`
      }

      onMounted(async () => {
        try {
          const response = await getCompareDates()
          dates.value = response.executionDates.map(date => formatDate(date))
        }
        catch (error) {
          console.error(error)
        }
      })

      return {
        modalOpen,
        handleNotUsableChoicesModal,
        handleNotUsableChoicesCloseModal,
        dates
      }
    }
  }
</script>

<style scoped>
  .not-usable-choices {
    &__btn-content {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &__btn-text {
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
    }
  }
</style>
