<template>
  <q-scroll-area class="fit">
    <div class="custom-bg">
      <div class="white-bg">
        <test-cases-table
          :test-case-id="routeParams?.testCaseId"
        ></test-cases-table>
        <test-cases-pagination></test-cases-pagination>
        <div v-if="routeParams?.testCaseId" class="flex column q-mx-lg">
          <test-cases-validations-table
            :test-case-id="routeParams?.testCaseId"
          ></test-cases-validations-table>
        </div>
      </div>
      <!-- Unusable choices here -->
      <div class="q-mt-lg white-bg">
        <unusable-choices-table></unusable-choices-table>
      </div>
    </div>
  </q-scroll-area>
</template>

<script>
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { TEST_CASES_VALIDATIONS_PAGE } from '@/utils/analytics/constants'
  import { onUnmounted } from 'vue'
  import { useRouteParams } from '@/composables/useRouteParams'
  import UnusableChoicesTable from '@/components/validations/test-cases/unusable-choices/UnusableChoicesTable.vue'
  import TestCasesTable from './TestCasesTable.vue'
  import TestCasesPagination from './TestCasesPagination.vue'
  import TestCasesValidationsTable from './TestCasesValidationsTable.vue'

  export default {
    name: 'TestCases',
    components: {
      TestCasesTable,
      TestCasesPagination,
      TestCasesValidationsTable,
      UnusableChoicesTable
    },
    setup() {
      const { routeParams } = useRouteParams()

      onUnmounted(() => {
        recordAnalytics(TEST_CASES_VALIDATIONS_PAGE)
      })

      return { routeParams }
    }
  }
</script>

<style lang="scss">
  .custom-bg {
    background-color: $mi-anthracite-50;
    padding: 24px;
  }

  .white-bg {
    background-color: $mi-white;
  }
</style>
