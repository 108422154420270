<template>
  <div class="browse-portfolio-view full-height">
    <browse-portfolio-search></browse-portfolio-search>
  </div>
</template>

<script>
  import BrowsePortfolioSearch from '@/components/browse-portfolio/BrowsePortfolioSearch.vue'

  export default {
    name: 'BrowsePortfolio',
    components: { BrowsePortfolioSearch }
  }
</script>

<style lang="scss" scoped>
  .browse-portfolio-view {
    width: 100vw;
    height: 100vh;
    padding: 24px;
  }
</style>
