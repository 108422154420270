<template>
  <div>
    <mi-table
      ref="miTable"
      :columns="columns"
      :loading="areTestCasesLoading"
      :rows="filteredTestCases.results"
      :table-colspan="columns.length"
      class="test-cases-table q-px-lg"
      :class="testCasesTableHeight"
      column-sort-order="da"
      loading-label="Loading test cases. Please wait ..."
      no-data-label="No test cases found"
      row-key="id"
      title="Test cases"
      virtual-scroll-sticky-size-start="40"
      hide-pagination
      sticky-header
      striped
      virtual-scroll
      wrap-cells
    >
      <template #top-left>
        <div class="row no-wrap">
          <div class="col-auto">
            <p class="q-table__title q-mb-sm">Test cases</p>
            <span class="test-cases-table__header-counter q-mb-sm">
              {{ testCasePageable.totalElements }} results found
            </span>
          </div>
          <div class="dropdown">
            <mi-btn-dropdown
              :menu-offset="[0, 4]"
              auto-close
              :label="selectedFilter"
              icon-type
              outline
              dropdown-icon="caret-down"
            >
              <mi-list>
                <mi-list-item
                  v-for="(filter) in filters"
                  :key="filter.key"
                  clickable
                  class="dropdown-item"
                  @click="filterTestCasesPinned(filter)"
                >
                  {{ filter.label }}
                </mi-list-item>
              </mi-list>
            </mi-btn-dropdown>
          </div>
          <div class="flex justify-between full-width">
            <mi-text-field
              :model-value="testCaseFilters.search"
              bg-color="white"
              debounce="300"
              placeholder="Search"
              clearable
              hide-bottom-space
              outlined
              class="q-ml-md search-input"
              @update:model-value="updateInputModelValue"
            >
              <template #prepend>
                <mi-icon name="search"></mi-icon>
              </template>
            </mi-text-field>
            <div class="btn-wrapper">
              <!-- Upload excel (test case) -->
              <mi-btn
                color="accent"
                icon-right="cloud-upload"
                icon-right-size="16px"
                @click="openUploadExcelDialog"
              >
                Upload Excel
              </mi-btn>
            </div>
          </div>
          <test-cases-dialog-upload v-model="isUploadExcelDialogShown"></test-cases-dialog-upload>
        </div>
      </template>
      <!-- Table row -->
      <template #body="{ key, row, rowIndex }">
        <mi-tr
          :key="key"
          :selected="testCaseId === row.id"
          class="table-row cursor-pointer"
          @click="toggleTestCasesValidations({ testCaseId: row.id, rowIndex })"
        >
          <mi-td> {{ row.name }} </mi-td>
          <mi-td> {{ row.creator }} </mi-td>
          <mi-td>
            <div class="flex items-center">
              {{ row.createdDate ? formatDate(row.createdDate) : '' }}
            </div>
          </mi-td>
          <test-cases-pin
            v-if="togglePinFeature"
            :row="row"
            :fetch-test-cases="fetchTestCases"
            :params="{ testCaseFilters, testCasePageable }"
          ></test-cases-pin>
        </mi-tr>

        <!-- handle the v-if in a prop when activating it -->
        <div v-if="backToTopBtn" class="back-to-top-btn">
          <mi-back-to-top-btn></mi-back-to-top-btn>
        </div>
      </template>
    </mi-table>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import formatDate from '@/utils/formatDate'

  import {
    SET_IS_NEW_VALIDATION_DIALOG_SHOWN,
    ARE_TEST_CASES_LOADING, SET_TEST_CASE_FILTERS, SET_TEST_CASE_PAGE
  } from '@/store/modules/validations/mutationTypes'
  import { TEST_CASES_ROUTE, TEST_CASE_VALIDATIONS_ROUTE } from '@/router/routeNames'
  import TestCasesPin from '@/components/validations/test-cases/TestCasesPin.vue'
  import useFeatureToggle from '@/composables/featureToggle'
  import { FEATURES } from '@/utils/featureToggle'
  import MiListItem from '@/packages/@mi-library/MiList/MiListItem.vue'
  import MiList from '@/packages/@mi-library/MiList/MiList.vue'
  import { TEST_CASES_FILTER } from '@/constants'
  import testCasesDialogUpload from '@/components/validations/test-cases/TestCasesDialogUpload.vue'

  const { mapActions, mapMutations, mapState } = createNamespacedHelpers('validations')

  export default {
    name: 'TestCasesTable',
    components: { testCasesDialogUpload, MiList, MiListItem, TestCasesPin },
    props: {
      testCaseId: {
        type: String,
        required: false,
        default: ''
      }
    },
    data: () => ({
      formatDate,
      backToTopBtn: false,
      togglePinFeature: false,
      columns: [
        { name: 'name', label: 'Name', align: 'left', field: 'name', style: 'word-break: break-word', sortable: true },
        { name: 'creator', label: 'Creator', align: 'left', field: 'creator', sortable: true },
        { name: 'createdDate', label: 'Uploaded date', align: 'left', field: 'createdDate', sortable: true }
      ],
      filters: TEST_CASES_FILTER,
      selectedFilter: TEST_CASES_FILTER.DISPLAY_ALL.label,
      isUploadExcelDialogShown: false
    }),
    computed: {
      ...mapState([
        'testCases',
        'filteredTestCases',
        'areTestCasesLoading',
        'testCaseFilters',
        'testCasePageable'
      ]),
      testCasesTableHeight() {
        return this.testCaseId ? 'test-cases-table-validations-opened' : ''
      }
    },
    watch: {
      testCaseFilters: {
        async handler({ date, search, creator, isPinned }) {
          const isValidSearchTerm = (search.length === 0 || search.length > 2) && /^[^'";=]*$/.test(search)

          if (isValidSearchTerm) await this.fetchTestCases({ creator, search, date, isPinned })
        },
        deep: true
      }
    },
    async created() {
      const { isEnabled } = useFeatureToggle(FEATURES.VUE_APP_TEST_CASES_PIN_TOGGLE)
      if (isEnabled.value) {
        this.columns.push(
          { name: 'isPinned', align: 'left', field: 'isPinned', sortable: false }
        )
      }
      this.togglePinFeature = isEnabled.value

      await this.fetchTestCases()

      if (this.testCaseId) {
        this.$nextTick(this.scrollToSelectedTestCaseOnLoad)
      }
      else {
        this.$refs.miTable?.sort('createdDate')
      }
    },
    unmounted() {
      this.resetTestCaseFiltersAndPagination()
    },
    methods: {
      ...mapActions(['getTestCasesList', 'resetTestCaseFiltersAndPagination']),
      ...mapMutations({
        SET_IS_NEW_VALIDATION_DIALOG_SHOWN,
        ARE_TEST_CASES_LOADING,
        SET_TEST_CASE_FILTERS,
        SET_TEST_CASE_PAGE
      }),

      async fetchTestCases(params) {
        this.ARE_TEST_CASES_LOADING(true)
        try {
          await this.getTestCasesList({ ...params })
        }
        finally {
          this.ARE_TEST_CASES_LOADING(false)
        }
      },
      openNewValidationDialog(testCaseId = '') {
        this.$router.push({
          name: TEST_CASE_VALIDATIONS_ROUTE.name,
          params: { testCaseId }
        })
        this.SET_IS_NEW_VALIDATION_DIALOG_SHOWN(true)
      },
      async filterTestCasesPinned(filter) {
        this.SET_TEST_CASE_FILTERS({ type: 'isPinned', val: filter.value })
        this.SET_TEST_CASE_PAGE({ page: 0 })
        this.selectedFilter = filter.label
      },
      updateInputModelValue(value) {
        this.SET_TEST_CASE_FILTERS({ type: 'search', val: value || '' })
      },
      scrollToSelectedTestCaseOnLoad() {
        const targetTestCaseIdx = this.testCases.findIndex(
          testCase => testCase.id === this.testCaseId
        )

        if (targetTestCaseIdx > -1) {
          this.$refs.miTable?.scrollTo(targetTestCaseIdx, 'center')
        }
      },
      toggleTestCasesValidations({ testCaseId = '', rowIndex } = {}) {
        if (this.testCaseId === testCaseId) {
          this.$router.push({ name: TEST_CASES_ROUTE.name })
        }
        else {
          this.$router.push({
            name: TEST_CASE_VALIDATIONS_ROUTE.name,
            params: { testCaseId }
          })

          if (!this.testCaseId) {
            setTimeout(() => this.$refs.miTable?.scrollTo(rowIndex, 'center'), 100)
          }
        }
      },
      openUploadExcelDialog() {
        this.isUploadExcelDialogShown = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .test-cases-table {
    min-height: 300px;
    max-height: 500px;

    .q-table__title {
      font-family: $mi-typography-font-family-condensed;
    }

    .table-row:hover {
      // stylelint-disable declaration-no-important
      background: $mi-anthracite-50 !important;
    }
  }

  .test-cases-table-validations-opened {
    height: 300px;
  }

  .test-cases-table .test-cases-table__status-cell {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .back-to-top-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin: 0;
    padding: 0;
    z-index: 999;
    width: 50px;
    height: 50px;
  }

  .test-cases-table__header-counter {
    color: #5b6f85;
    font-size: 12px;
    font-style: normal;
    font-family: $mi-typography-font-family-condensed;
    font-weight: 700;
    line-height: 130%;
  }

  ::v-deep(.q-table-control) {
    width: 100%;
  }

  .dropdown {
    margin-left: 11.2px;
  }

  .dropdown-item {
    color: #303c49;
  }

  .search-input {
    width: 600px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
</style>
