<template>
  <div>
    <mi-table
      ref="miTable"
      :columns="table.columns"
      :loading="table.loading"
      :rows="table.rows"
      :table-colspan="table.columns.length"
      class="unusable-choices-table q-px-lg"
      column-sort-order="da"
      loading-label="Loading unusable choices. Please wait ..."
      no-data-label="No unusable choices found"
      row-key="id"
      title="Unusable Choices"
      virtual-scroll-sticky-size-start="40"
      hide-pagination
      sticky-header
      striped
      virtual-scroll
      wrap-cells
    >
      <template #top-left>
        <div class="unusable-choices-wrapper">
          <div>
            <p class="q-table__title q-mb-sm">Not usable choices</p>
            <span class="unusable-choices-table__header-counter q-mb-sm">
              {{ pagination.totalElements }} results found
            </span>
          </div>
          <not-usable-choices-modal v-if="isEnabled"></not-usable-choices-modal>
        </div>
      </template>

      <!-- Status column -->
      <template #body-cell-status="{ value, row }">
        <!-- Validation status -->
        <mi-td class="unusable-choices-table__status-cell">
          <div class="flex items-center justify-between">
            <mi-badge class="text-uppercase q-mr-lg" :color="value.color">
              {{ value.title }}
            </mi-badge>
            <!-- Dropdown and Arrow -->
            <div class="interaction-section">
              <mi-btn color="transparent" @click.stop>
                <q-icon class="text-dark" size="small">
                  <img
                    src="@/assets/images/dots_vertical.svg"
                    alt="Export to excel"
                  />
                </q-icon>
                <mi-menu>
                  <q-list>
                    <q-item
                      v-close-popup
                      clickable
                      class="export-data-btn"
                      @click="exportUnusableChoices(row.id)"
                    >
                      <q-item-section>
                        <q-item-label>
                          Export data
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </mi-menu>
              </mi-btn>
              <mi-btn
                v-if="row.status === 'PROCESSED'"
                color="transparent"
                @click="handleRedirectToUnusableChoicesPage(row.id)"
              >
                <q-icon class="text-dark" size="small">
                  <img
                    src="@/assets/images/redirect_arrow.svg"
                    alt="Navigate to Validation Results"
                  />
                </q-icon>
              </mi-btn>
            </div>
          </div>
        </mi-td>
      </template>
    </mi-table>
  </div>
  <div class="pagination q-lg flex flex-center">
    <mi-pagination
      v-if="!!table.rows.length"
      :model-value="pagination.currentPage"
      :max="pagination.totalPages"
      :max-pages="pagination.maxNavPage"
      size="14px"
      boundary-links
      direction-links
      boundary-numbers
      ellipses
      @update:model-value="handlePaginationChange"
    ></mi-pagination>
  </div>
</template>

<script>
  import { onMounted, ref, watch } from 'vue'
  import { usePagination } from '@/composables/usePagination'
  import { useTable } from '@/composables/useTable'
  import { exportUnusable, getUnusableChoices } from '@/api'
  import { unusableChoicesListColumns } from '@/components/validations/test-cases/unusable-choices/utils'
  import MiMenu from '@/packages/@mi-library/MiMenu/MiMenu.vue'
  import { exportFile } from 'quasar'
  import { useRouter } from 'vue-router'
  import { UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE as UNUSABLE_CHOICE_ROUTE } from '@/router/routeNames'
  import { useStore } from 'vuex'
  import { getUTCTodayDate } from '@/utils/formatDate'
  import NotUsableChoicesModal
    from '@/components/validations/test-cases/unusable-choices/not-usable-choices-modal/NotUsableChoicesModal.vue'
  import useFeatureToggle from '@/composables/featureToggle'
  import { FEATURES } from '@/utils/featureToggle'

  export default {
    name: 'UnusableChoicesTable',
    components: { MiMenu, NotUsableChoicesModal },
    setup() {
      const router = useRouter()
      const { pagination, handleChange: handlePaginationChange } = usePagination()
      const { table, callbacks: { fetchTableData } } = useTable(unusableChoicesListColumns)
      const store = useStore()
      const filters = ref(store.state.validations.testCaseFilters)
      const todayDate = getUTCTodayDate(new Date())
      const { isEnabled } = useFeatureToggle(
        FEATURES.VUE_APP_COMPARE_MODAL_TOGGLE
      )

      const buildUrlSearchParams = (creator, date) => {
        const params = new URLSearchParams()
        params.append('page', pagination.currentPage - 1)
        params.append('pageSize', pagination.pageSize)
        creator ? params.append('creator', creator) : ''
        date ? params.append('date', date) : ''
        return params.toString()
      }

      const fetchUnusableChoicesList = async filtersParams => {
        const params = buildUrlSearchParams(filtersParams?.creator, filtersParams?.date)
        const fetchUnusableChoicesListPromise = () => getUnusableChoices(params)
        const data = await fetchTableData(fetchUnusableChoicesListPromise)
        pagination.totalPages = data?.totalPages || 1
        pagination.totalElements = data?.totalElements
      }

      watch(() => [filters.value.creator, filters.value.date, pagination.currentPage, filters.value.refresh],
            ([newCreator, newDate], [oldCreator, oldDate]) => {
              const filtersChanged = newCreator !== oldCreator || newDate !== oldDate

              if (filtersChanged && pagination.currentPage !== 1) {
                pagination.currentPage = 1
              }
              else {
                fetchUnusableChoicesList({
                  creator: filters.value.creator,
                  date: newDate !== '' ? todayDate : ''
                })
              }
            })

      onMounted(async () => {
        await fetchUnusableChoicesList()
      })

      const exportUnusableChoices = async rowId => {
        const exelFile = await exportUnusable(rowId)
        const filename = `unusable_choice_${ rowId }.xlsx`
        exportFile(filename, new Blob([exelFile]))
      }

      const handleRedirectToUnusableChoicesPage = id => {
        router.push({ name: UNUSABLE_CHOICE_ROUTE.name, params: { validationId: id } })
      }

      return {
        table,
        pagination,
        filters,
        handlePaginationChange,
        buildUrlSearchParams,
        fetchUnusableChoicesList,
        exportUnusableChoices,
        handleRedirectToUnusableChoicesPage,
        isEnabled
      }
    }
  }
</script>

<style lang="scss" scoped>
  .unusable-choices-table {
    min-height: 300px;
    max-height: 500px;

    .q-table__title {
      font-family: $mi-typography-font-family-condensed;
    }
  }

  .unusable-choices-table-validations-opened {
    height: 300px;
  }

  .unusable-choices-table .unusable-choices-table__status-cell {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .unusable-choices-table__header-counter {
    color: #5b6f85;
    font-size: 12px;
    font-style: normal;
    font-family: $mi-typography-font-family-condensed;
    font-weight: 700;
    line-height: 130%;
  }

  .header-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  ::v-deep(.q-table-control) {
    width: 100%;
  }

  .header-content {
    width: 35%;
  }

  .pagination {
    padding: 8px 0 16px;
  }

  .unusable-choices-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
</style>
